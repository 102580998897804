<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Settings.Account.Title") }}
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <ValidationProvider
                v-slot="{ valid, errors }"
                tag="div"
                rules="required|min:2"
                class="form-field field"
            >
                <div class="floating-control control has-icons-right">
                    <input
                        v-model="Form.Name"
                        class="input"
                        :class="{ 'is-danger': errors[0] }"
                        :name="$t('Interface.Settings.Account.Name')"
                        type="text"
                        data-cy="Name"
                        required
                        @input="Form.Name = $event.target.value"
                    >
                    <label class="label">
                        {{ $t("Interface.Settings.Account.Name") }}
                    </label>
                    <span v-if="valid" class="goto-icon icon is-right">
                        <i class="goto-icon-check" />
                    </span>
                </div>
                <p v-if="errors[0]" class="help is-danger">
                    <span class="goto-icon icon is-small is-right">
                        <i class="goto-icon-error" />
                    </span>
                    {{ errors[0] }}
                </p>
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ valid, errors }"
                tag="div"
                rules="required|email"
                class="form-field field"
            >
                <div class="floating-control control has-icons-right">
                    <input
                        v-model="Form.Email"
                        class="input"
                        :class="{ 'is-danger': errors[0] }"
                        :name="$t('Interface.Settings.Account.Email')"
                        type="text"
                        data-cy="Email"
                        :disabled="Form.IsEmailConfirmed === true"
                        required
                    >
                    <label class="label">
                        {{ $t("Interface.Settings.Account.Email") }}
                    </label>
                    <span v-if="valid" class="goto-icon icon is-right">
                        <i class="goto-icon-check" />
                    </span>
                </div>
                <p v-if="errors[0]" class="help is-danger">
                    <span class="goto-icon icon is-small is-right">
                        <i class="goto-icon-error" />
                    </span>
                    {{ errors[0] }}
                </p>
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ valid, errors }"
                tag="div"
                rules="required|phone"
                class="form-field field"
                :name="$t('Interface.Settings.Account.Phone')"
            >
                <div class="floating-control control has-icons-right">
                    <cleave
                        v-model="Form.Phone"
                        class="input"
                        :class="{ 'is-danger': errors[0] }"
                        :options="CustomPhone"
                        type="text"
                        data-cy="Phone"
                        :disabled="Form.IsPhoneVerified === true"
                        required
                    />
                    <label class="label">
                        {{ $t("Interface.Settings.Account.Phone") }}
                    </label>
                    <span v-if="valid" class="goto-icon icon is-right">
                        <i class="goto-icon-check" />
                    </span>
                </div>
                <p v-if="errors[0]" class="help is-danger">
                    <span class="goto-icon icon is-small is-right">
                        <i class="goto-icon-error" />
                    </span>
                    {{ errors[0] }}
                </p>
            </ValidationProvider>

            <button
                type="submit"
                class="button is-theme"
                :disabled="invalid"
                @click.prevent="handleSubmit(submit)"
            >
                {{ $t("Interface.Button.Submit") }}
            </button>
        </ValidationObserver>
        <b-loading
            :is-full-page="true"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
import Cleave from "vue-cleave-component"
// eslint-disable-next-line no-unused-vars
import CleavePhone from "cleave.js/dist/addons/cleave-phone.i18n"
import LoadingState from "@/mixins/LoadingState"
import { ACCOUNT_UPDATE } from "@/store/actions/account"

export default {
    name: "PageAccount",
    components: {
        Cleave
    },
    mixins: [LoadingState],
    data() {
        return {
            CustomPhone: {
                phone: true,
                prefix: "+",
                numericOnly: true,
                noImmediatePrefix: true
            },
            Form: {
                Name: this.$store.state.Account.Name,
                Email: this.$store.state.Account.Email,
                Phone: `+${this.$store.state.Account.Phone}`,
                IsPhoneVerified: this.$store.state.Account.IsPhoneVerified,
                IsEmailConfirmed: this.$store.state.Account.IsEmailConfirmed
            }
        }
    },
    mounted() {
        this.$store.subscribe((mutation) => {
            if (mutation.type === "ACCOUNT_REQUEST_SUCCESS") {
                this.Form.Name = this.$store.state.Account.Name
                this.Form.Email = this.$store.state.Account.Email
                this.Form.Phone = `+${this.$store.state.Account.Phone}`
                this.Form.IsPhoneVerified = this.$store.state.Account.IsPhoneVerified
                this.Form.IsEmailConfirmed = this.$store.state.Account.IsEmailConfirmed
            }
        })
    },
    methods: {
        submit() {
            this.switchLoading()
            this.$store.dispatch(ACCOUNT_UPDATE, this.Form)
                .then(() => {
                    this.$router.push({ name: "Dashboard" })
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Settings.Account.Title")
        }
    }
}
</script>
